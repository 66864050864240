@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Biryani|Rammetto+One');

$primary: #79BD8F;/* MAIN COLOR */
$secondary: #9b3b35; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
    font-family: 'Biryani', sans-serif;
}

body {
    background: url(../img/memphis-colorful.png) repeat;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
    font-family: 'Biryani', sans-serif;
}

h1,h2,h3,h4,h5 {
    font-family: 'Rammetto One', cursive;
    @include fluid-type($min_width, $max_width, 16px, 30px);
}


body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin-top: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    // padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: $primary;
        color: lighten($wht, 10%);
        text-decoration: none;
        box-shadow: none;
        @media (max-width: 1024px) {
            background: $primary;
            color: $wht;
        }
    }
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        color: black;
        outline: 0;
        height: 30px;
        line-height: 30px;
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
        padding: 0.5rem;
        vertical-align: middle;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 150px;
    }
    .navbar-header a {
        float: left;
    }
}



.top-pad {
    padding: 0.5em;
    background: white;
    font-family: 'Biryani', sans-serif;
    li {
        font-family: 'Biryani', sans-serif;
    }
    p {
        font-family: 'Biryani', sans-serif;
    }
    h1,h2,h3,h4,h5 {
          @include fluid-type($min_width, $max_width, 14px, 30px);
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.banner {
    background: url(../img/banner.jpg) no-repeat;
    background-size: cover;
    padding: 14rem 0;
    background-position: 50%;
    background-attachment: fixed;
    @media (max-width: 1024px) {
        background-attachment: scroll;
        background-position: 50% 80%;
    }
    @media (max-width: 991px) {
        padding: 12rem 0;
    }
    @media (max-width: 767px) {
        padding: 10rem 0;
    }
    h1,h2 {
        color: white;
        padding: 0 1rem;
    }
}

a.cbtn {
    padding-top: 8px;
    @include btn;
}

.introSection {
    background: white;
    margin-top: -50px;
    border-radius: 25px;
    .couples {

        @media (max-width: 991px) {
            display: block;
            margin: 0rem auto 1rem auto;
            max-width: 400px;
            width: 100%;
        }
    }
}

.txtSection {
    .txtBox1 {
        background: $primary;
        padding: 5rem 1rem;
    }
    .txtBox2 {
        background: $secondary;
        padding: 5rem 1rem;
    }
    .innerBox {
        background: white;
        padding: 1rem;
        box-shadow: 4px 3px 10px rgba(0,0,0,0.5);
    }
}

.navbar-default {
    background: lighten($primary, 30%);
}

span[class*="ti"] {
    font-size: 1.1rem;
    display: inline-block;
    vertical-align: middle;
    color: white;
    
}
.panel-heading {
    padding: 0;
    height: 40px;
    background: $secondary !important;
}
.panel-heading:before {
  content: "";
  display: inline-block;
  height: 100%; 
  vertical-align: middle;
} 

.panel-title {
  display: inline-block;
  vertical-align: middle;
  color: white;
}

.tble {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    @media (max-width: 991px) {
        display: block;
    }
    .tble-cell {
        display: table-cell;
        float: none;
        vertical-align: middle;
        @media (max-width: 991px) {
            display: block;
        }
    }
}

.midSection {
    padding-top: 3rem;
    padding-bottom: 3rem;
    .heart {
        @media (max-width: 991px) {
            display: block;
            margin: 1rem auto;
            max-width: 400px;
            width: 100%;
        }
    }
}

.bg {
    background: linear-gradient(
        rgba($secondary, 0.5),
        rgba($secondary, 0.5)
        ),url(../img/flowers.jpg) no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 5rem 0;
    h1 {
        color: white;
    }
}

.navbar-default .navbar-toggle, .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background: white;
}